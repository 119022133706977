export const ApiPrefix = {
  Auth: 'api/login/',
  Config: 'api/v1/',
  Login: 'api/v1/',
  User: 'api/v1/',
};

export const MiscApi = {
  Settings: '/settings',
  Log: '/log',
};

export const ConfigApi = {
  Static: {
    GetAll: 'static/data',
  },
  auth: '/api/v1/authn'
};

export const UserApi = {
  ValidateSession: 'login/validate-session',
  GetDetails: '',
};

export const HomeApi = {
  InventoryByPharmacy: 'ndexDashboard/inventoryWidget',
  GetDoseDistribution: 'ndexDashboard/geographicalWidget',
  Get90DayOrderDetails: 'ndexDashboard/90DaysOrderWidget',
  GetTopTrendingDetails: 'ndexDashboard/trendingCustWidget',
  saveAllWidget: 'ndexDashboard/allWidget',
  getAllWidget: 'ndexDashboard/getAllWidget',
  GetNewCustomerDetails: 'ndexDashboard/newCustomerWidget'
}

export const InventoryApi = {
  GetDetails: 'inventory/details?page=',
  UploadDdq: 'ddq/upload',
  DownloadDdq: 'ddq/download',
  UpdateDdqStatus: 'ddq/ddqstatus'
};

export const LogisticsApi = {
  GetDetails: 'logistics?page=',
};

export const DashboardApi = {
  GetDeliveryStats: 'ndexDashboard/getDeliveryStats',
  DoseDeliveryStatus: 'ndexDashboard/doseDeliveryStatus',
  ActiveRoutes: 'ndexDashboard/allActiveRoutes',
  DeliveryDetails: 'ndexDashboard/doseDeliveryStatusPerDay',
};

export const OrdersApi = {
  GetStatus: 'orders/status',
  GetOrderDetails: 'orders/detail?page=',
  GetShipto : 'orders/shiptos',
  GetOrderLocation : 'orders/locations'  
};

export const MasterDataApi = {
  LocationData: 'master/location',
  GetCustomerMaster: 'master/customer',
  GetProductOrderMaster: 'master/product/order',
  GetProductInventoryMaster: 'master/product/inventory',
};

export const AdminApi = {
  UserSearch: 'admin/userdetails',
  GetLinkedShipTos: 'shipToAdmin/custShiptodetails?userId=',
  SearchShipTo: 'admin/shiptodetails?serachParam=',
  SaveUser: 'admin/save',
  GetShipToDetails: 'shipToAdmin/shipto/user/detail?shipToNum=',
  GetTiers: 'shipToAdmin/getTier',
  SaveShipToTier: 'shipToAdmin/shipto/tier/save',
  SearchProduct: 'shipToAdmin/products?searchString=',
  RemoveShipToProduct: 'shipToAdmin/custProdTier/remove',
  SaveShipToProducts: 'shipToAdmin/save',
  RemoveShipToUser: 'admin/user/custshipto/remove',
  SaveWebHooks: 'shipToAdmin/webhook/save',
  removeWebHook: 'shipToAdmin/removeCustShipToWebhookRlt',
  SaveInvoiceFlag: 'shipToAdmin/shiptoinvoice/save',
  SaveReportAccessFlag: 'admin/report/access',
  SaveDdqAccessFlag: 'admin/ddq/access'
};

export const ReportApi = {
  ReportsList: 'report/position',
  RequestReport: 'report/generate?reportTemplateNum=',
  GetDownloadReports: 'report/allResults',
  DownloadReport: 'report/download?reportName=',
  DeleteReport: 'report/deleteReport?reportResultNum=',
  SaveScheduleReport: 'report/schedule/save',
  GetScheduledReports: 'report/schedule/allSchedules',
  ChangeScheduleEmailAlert: 'report/schedule/emailAlert',
  DeleteScheduledReport: 'report/schedule/delete?userFrqncyNum=',
  GetScheduledFrequency: 'report/schedule/allfrequency',
  GetProducts: 'report/getProducts',
  GetAllReports: 'report/all-reports',
  GetCustomConfig: 'report/templates',
  DeleteCustomReport: 'report/customreport',
  GetReportDrivers: 'report/driver-details',
  GetColumnFilterData: 'report/filter-values?columnId=',
  SaveCustomReport: 'report/template/save',
  PreviewCustomReport: 'report/preview'
};

export const InvoiceApi = {
  InvoiceReport: 'invoice/search',
  DownloadInvoice: 'invoice/download',
  GetShipTos: 'invoice/shipto'
};

export const SettingsApi = {
  GetEmailNotificationSettings: 'orders/order-email-details',
  SaveEmailNotificationSettings: 'orders/saveOrderEmail',
  GetAllOrderEmailNotificationSettings: 'orders/notifications',
  DeleteOrderEmailNotificationSetting: 'orders/delete',
  GetExcursionGeoFenceNotificationSettings: 'logistics/getExcursionAndGeoFenceEmail',
  SaveExcursionGeoFenceNotificationSettings: 'logistics/saveExcursionAndGeoFenceEmail',
  GetAllExcursionsGeoFenceNotificationSettings: 'logistics/notifications',
  DeleteExcursionGeoFenceNotificationSetting: 'logistics/delete-notification'
}

export enum SettingParams {
  CustomerShipTo = 'customerShipTo',
  ProdCnfgNum = 'prodCnfgNum'
}
