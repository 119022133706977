<footer id="footer" [ngClass]="{'flex-between': featureFlag, 'flex-center': !featureFlag}">
  <div class="copy-right">
    © {{year}} Cardinal Health. All Rights Reserved. CARDINAL HEALTH and the
    Cardinal Health LOGO are trademarks of Cardinal Health and may be registered
    in the US and/or in other countries.
  </div>
  <div *ngIf="featureFlag" class="date-time">
    Date/Time displayed in NDEX are GMT.
  </div>
</footer>
