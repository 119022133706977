import { Component } from '@angular/core';
import { Environment } from 'src/app/core/environment/environment.model';

@Component({
  selector: 'ndex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  featureFlag = Environment.getInstance().appConfig.featureFlag;
  year = new Date().getFullYear()
}
